import React, { useState } from 'react';
import { FormProvider, RHFDateField, RHFTextField, RHFCheckbox } from '../../../common/hook-form';
import { useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { apiConstants, postGeneric } from '../../../common/constants';
import { toast } from "react-toastify";
import { useSelector } from 'react-redux';
import { convertDate } from '../../../helpers/UtilityHelper';
import { Button, Grid } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from "yup";


const LabelDownloadBulk = () => {
    const merchantId = useSelector((state) => state.user.userInfo.merchant.id);
    const [isDownloadingFile, setIsDownloadingFile] = useState(false)

    const method = useForm({
        resolver: yupResolver(Yup.object().shape({
            date: Yup.date()
                .typeError('Date is required')
                .required('Date is required'),
            pincode: Yup.string()
                .test('valid-pincode', 'Invalid pincode', value => {
                    if (!value) {
                        return false; // Empty value
                    }

                    const regex = /^\d{6}$/; // 6-digit number
                    return regex.test(value) || value.length !== 6;
                })
                .required("Pincode is a mandatory field")
                .test('length', 'Please enter a 6 digit pincode', value => {
                    return !value || value.length === 6;
                })
                .test('valid', 'Please enter a valid pincode', value => {
                    if (!value || value.length !== 6) {
                        return true; // Skip validation for non-6 digit values
                    }

                    const regex = /^\d{6}$/; // 6-digit number
                    return regex.test(value);
                }),
        })),
        defaultValues: {
            pincode: '',
            date: null,
            cancelled_orders: false,
            manifested_orders: false
        }
    });
    const { handleSubmit, reset } = method;

    const onSubmit = async (data) => {
        setIsDownloadingFile(true)
        try {
            await postGeneric(`${apiConstants.GENERATE_BULK_LABELS}?date=${convertDate(data.date)}&pincode=${data.pincode}&merchantId=${merchantId}&shipmentType=${'B2C'}&includeCancelOrders=${data.cancelled_orders}&includeManifested=${data.manifested_orders}`).then(response => {
                window.open(response.data?.mergedPdf, true)
            }).catch(err => {
                console.error(err);
                const message = err?.response?.data?.message || err.message
                toast(message || "Error downloading file", { type: 'error', theme: "colored" })
                setIsDownloadingFile(false);
            });
        } catch (err) {
            const message = err?.response?.data?.message || err.message
            toast(message || "Error downloading file", { type: 'error', theme: "colored" })
        }

        setIsDownloadingFile(false)
    }

    return (
        <>
            {/* <Box width={1} sx={{ backgroundColor: '#fff', boxShadow: 1, p: 3, mb: '100px' }}> */}
            <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                    <Grid item xs={3}>
                        <RHFDateField label="Date" name="date" />
                    </Grid>
                    <Grid item xs={3}>
                        <RHFTextField
                            label="Pincode *"
                            name="pincode"
                            size='medium'
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ mt: 1 }}>
                        <RHFCheckbox
                            name="cancelled_orders"
                            mandatory="false"
                            label={(
                                <>
                                    Include Cancelled Orders
                                </>
                            )}
                        />
                    </Grid>
                    <Grid item xs={3} sx={{ mt: 1 }}>
                        <RHFCheckbox
                            name="manifested_orders"
                            mandatory="false"
                            label={(
                                <>
                                    Include Manifested Orders
                                </>
                            )}
                        />
                    </Grid>
                    {/* <Grid item xs={3}>
                                <RHFCheckbox
                                    name="manifested_orders"
                                    mandatory="false"
                                    label={(
                                        <>
                                            Include Manifested Orders
                                        </>
                                    )}
                                />
                            </Grid> */}
                    <Grid item xs={10} display={'flex'} alignItems={'center'}>
                        <LoadingButton
                            type="submit"
                            loading={isDownloadingFile}
                            variant="contained"
                            color="primary"
                            size="medium">
                            Download
                        </LoadingButton>
                        <Button
                            sx={{ ml: 1 }}
                            variant="contained"
                            color="error"
                            size="medium"
                            onClick={() => {
                                reset()
                            }}
                        >
                            Reset
                        </Button>
                    </Grid>
                </Grid>
            </FormProvider>
            {/* </Box> */}
        </>
    )
}
export default LabelDownloadBulk;