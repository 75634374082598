import {
  Button,
  Chip,
  Grid,
  List,
  ListItem,
  Typography,
  Paper,
  Stack
} from "@mui/material";
import { useForm } from 'react-hook-form';
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import ProzoDataGrid from "../../../common/ProzoDataGrid";
import {
  ChannelIcon,
  formatCurrency,
  formatDate,
  formatNumber,
} from "../../../helpers/UtilityHelper";
import { useGetChannelOrderQuery } from "../../../redux/commonRTK";
import _ from "lodash";
import Loader from "../../../common/Loader";
import MUIModal from "../../../common/MUIModal";
// import { getMerchant } from "../../../helpers/ReduxHelpers";
import {
  // getGeneric,
  apiConstants,
  postGeneric,
} from "../../../common/constants";
import { Link } from "react-router-dom";
import { FormProvider, RHFSelectField, RHFTextField } from "../../../common/hook-form";
import CheckCircleIcon from '@mui/icons-material/Download';
import { LoadingButton } from "@mui/lab";
import { getMerchant } from "../../../helpers/ReduxHelpers";
import { CancelSharp } from "@mui/icons-material";

export default function FulfilledChannelOrders() {
  const [page, setPage] = useState(0);
  const [sizePerPage, setSizePerPage] = useState(10);
  const [orderItems, setOrderItems] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  // const [placeOrderFor, setPlaceOrderFor] = useState([]);
  // const [merchant, setMerchant] = useState(null);
  const [andFilters, setAndFilters] = useState([]);
  const [skuDetails, setSkuDetails] = useState([]);
  const [finalData, setFinalData] = useState([]);
  const [loading, setIsLoading] = useState(false)

  const merchant = getMerchant();
  const merchantId = merchant.id ? merchant.id : ''

  const defaultFilters = [
    { field: "channelOrderStatus", operator: "eq", value: "FULFILLED" },
    { field: "merchant.$id", operator: "eq", value: merchantId }
  ]

  const methods = useForm({
    defaultValues: {
      order_number: '',
      awb_number: '',
      email: '',
      phone: '',
      awbNumber: '',
      order_verification: ''
    }
  });


  const {
    handleSubmit,
    reset
  } = methods;

  const { data, isLoading, isFetching, refetch } = useGetChannelOrderQuery({
    andfilter: [...defaultFilters, ...andFilters],
    orfilter: [],
    offset: page * sizePerPage,
    limit: sizePerPage,
    "excludes": "sla,merchant,mappedOrderRule"
  });


  // const { id: merchantId } = getMerchant();

  // useEffect(() => {
  //   if (merchantId) {
  //     getGeneric(`${apiConstants.GET_MERCHANT}/${merchantId}`)
  //       .then((response) => {
  //         setMerchant(response.data);
  //       })
  //       .catch((err) => console.error(err));
  //   }
  // }, [merchantId]);

  useEffect(() => {
    if (data?.data?.length > 0) {
      let skus = data?.data?.map((row) => row.orderItems);
      skus = skus.flat().map((row) => row.sku_id);
      skus = [...new Set(skus)];
      postGeneric(apiConstants.GET_SKU_DIM, { skuList: skus })
        .then((_response) => {
          setSkuDetails(_response.data);
        })
        .catch((_err) => {
          console.log(_err);
        });
    }
  }, [data]);

  useEffect(() => {
    if (data?.data?.length > 0) {
      const fData = data?.data?.map((row) => {
        const rowItems = row?.orderItems?.map((oi) => {
          const skuDetail = skuDetails?.find(
            (sku) => sku.skuCode === oi.sku_id
          );
          return { ...oi, ...skuDetail };
        });

        return { ...row, orderItems: rowItems };
      });

      setFinalData(fData);
    } else if (data?.data?.length === 0) {
      setFinalData([])
    }
  }, [skuDetails, data]);

  const applyPickupFilters = (filters) => {
    let appliedPickupFilters = [];
    if (filters.order_number) {
      appliedPickupFilters.push({ "field": "order_number", "operator": "eq", "value": filters.order_number })
    }
    if (filters.awb_number) {
      appliedPickupFilters.push({ "field": "awbNumber", "operator": "eq", "value": filters.awb_number })
    }
    if (filters.payment_mode) {
      appliedPickupFilters.push({ "field": "paymentMode", "operator": "eq", "value": filters.payment_mode })
    }
    if (filters.phone) {
      appliedPickupFilters.push({ "field": "customer.phone", "operator": "eq", "value": '+91' + filters.phone })
    }
    if (filters.email) {
      appliedPickupFilters.push({ "field": "customer.email", "operator": "eq", "value": filters.email })
    }
    if (filters.store_name) {
      appliedPickupFilters.push({ "field": "store_name", "operator": "eq", "value": filters.store_name })
    }
    if (filters.order_verification) {
      appliedPickupFilters.push({ "field": "order_verification", "operator": "eq", "value": filters.order_verification === 'No' ? null : filters.order_verification })
    }
    if (filters.awbNumber) {
      appliedPickupFilters.push({ "field": "proshipOrder.awbNumber", "operator": "eq", "value": filters.awbNumber })
    }


    setAndFilters(appliedPickupFilters)
    handleAndFiltersChange(appliedPickupFilters);
  }
  const handleAndFiltersChange = (newAndFilters) => {
    refetch({
      andfilter: [...defaultFilters, ...newAndFilters], // Update andfilter with new filters
    });
  };


  const columns = [
    {
      field: 'channel',
      sortable: false,
      flex: 0.9,
      headerAlign: "center",
      headerName: 'Order No',
      renderCell: (params) => {
        const { row } = params;
        return (
          <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <span>{row.order_number}</span>
            <span><ChannelIcon name={row.channel} /></span>
          </div>
        );
      },
    },
    {
      field: "store_name",
      flex: 1,
      sortable: false,
      headerAlign: "left",
      headerName: "Store Name",
    },
    {
      field: 'order_verification',
      sortable: false,
      flex: 1.1,
      headerAlign: "left",
      headerName: 'WhatsApp Verification',
      renderCell: (params) => {
        const { row } = params;

        if (row.order_verification === "true") {
          return (
            <CheckCircleIcon color='success' />
          );
        } else if (row.order_verification === "false") {
          return (
            <CancelSharp color='error' />
          );
        } else {
          return (
            <span>NA</span>
          );
        }
      },
    },
    {
      field: "channelOrderId",
      width: 1,
      flex: 1,
      sortable: false,
      headerAlign: "left",
      headerName: "Order Details",
      renderCell: ({ row }) => {
        return (
          <Box>
            <Typography textAlign="left" variant="body2" fontWeight={500}>
              {formatDate(row.createdDate, "dd MMM, yyyy")}
            </Typography>
            <Typography textAlign="left" variant="body2">
              ({formatDate(row.createdDate, "hh:mm aaa")})
            </Typography>
            <Typography textAlign="left" variant="body2">
              {row.order_number}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: 'customer', flex: 1.1, sortable: false, headerAlign: "left", headerName: 'Customer Details', renderCell: ({ row }) => {
        return (
          <Box>
            <Typography textAlign="left" variant="body2" fontWeight={500}>
              {row.customer?.first_name}
            </Typography>
            <Typography sx={{ wordBreak: 'break-word' }} textAlign="left" variant="body2">
              {row.customer?.email}
            </Typography>
            {/* <Typography textAlign="left" variant="body2">
              {row.customer?.phone}
            </Typography> */}
          </Box>
        );
      },
    },
    {
      field: "courierPartner",
      flex: 1,
      sortable: false,
      align: "left",
      headerName: "Courier Partner",
      renderCell: (params) => {
        const { row } = params;
        let proshipOrders = row.proshipOrder;
        return (
          <>
            <Grid container spacing={2}>
              {proshipOrders?.map((o, i) => (
                <Grid item xs={12}>
                  <Typography textAlign="left" variant="body2" color={"black"} fontSize={"14px"} fontWeight={400}>{o?.courierDetail?.parent}</Typography>
                </Grid>
              ))}
            </Grid>
          </>
        );
      },

    },
    {
      field: "awb",
      flex: 1.2,
      sortable: false,
      headerAlign: "left",
      headerName: "AWB Number",
      renderCell: (params) => {
        const { row } = params;
        let proshipOrders = row.proshipOrder;
        return (
          <>
            <Grid container spacing={2}>
              {proshipOrders?.map((o, i) => (
                <Grid item xs={12}>
                  <Link to={`/order-detail?waybill=${o.awbNumber}&verified=${row?.order_verification ? true : null}`}>
                    {o?.awbNumber}
                  </Link>
                </Grid>
              ))}
            </Grid>
          </>
        );
      },
    },
    {
      field: "status",
      flex: 1.2,
      sortable: false,
      headerAlign: "left",
      headerName: "Current Status",
      renderCell: (params) => {
        const { row } = params;
        let proshipOrders = row.proshipOrder;
        return (
          <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <List>
                  {proshipOrders?.map((o, i) => (
                    <ListItem key={i}><Typography textAlign="left" variant="body2" color={"black"} fontSize={"14px"} fontWeight={400}>{o?.currentStatus}</Typography></ListItem>
                  ))}
                </List>
              </Grid>
            </Grid>
          </>
        );
      },
    },
    {
      field: "items",
      flex: 0.8,
      sortable: false,
      headerAlign: "center",
      headerName: "Items",
      renderCell: (params) => {
        const { row } = params;
        return (
          <Button
            onClick={() => setOrderItems(row.orderItems)}
            sx={{
              letterSpacing: 0,
              fontSize: 12,
            }}
            size="small"
          >
            View Items
          </Button>
        );
      },
    },
    {
      field: "paymentDetails",
      headerAlign: "center",
      sortable: false,
      align: "center",
      headerName: "Payment Details",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        const paymentMode = row.payment_mode ? row.payment_mode : "";
        const color = paymentMode === "COD" ? "error" : "success";
        return (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}
          >
            {row.total_price ? (
              <Typography textAlign="center" variant="body2">
                Order Value: {formatCurrency(row.total_price)}
              </Typography>
            ) : (
              ""
            )}
            <Chip
              size="small"
              sx={{ fontWeight: 500, mt: 1 }}
              color={color}
              label={paymentMode}
            />
          </Box>
        );
      },
    },
    {
      field: 'tags', flex: 1.2, sortable: false, headerAlign: "left", headerName: 'Tags',
      renderCell: ({ row }) => {
        if (row.tags) {
          const tags = row.tags.split(',')
          return (
            <React.Fragment>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div>
                    {tags.map((t) => <Chip size="small" sx={{ m: '2px', fontSize: 10, fontWeight: 700 }} color='default' label={t.trim().toUpperCase()} />)}
                  </div>
                </Grid>
              </Grid>
            </React.Fragment>
          )
        }
      }
    },
    {
      field: "channelOrderStatus",
      headerAlign: "center",
      sortable: false,
      align: "center",
      flex: 1,
      headerName: "Channel Status",
      renderCell: ({ row }) => {
        return (
          <Box>
            <Typography sx={{ mt: 1 }} textAlign="center" variant="body2">
              {_.startCase(row.channelOrderStatus)}
            </Typography>
          </Box>
        );
      },
    },
    // {
    //   field: "mesurements",
    //   headerAlign: "center",
    //   align: "center",
    //   sortable: false,
    //   headerName: "Channel Weight",
    //   flex: 1,
    //   renderCell: ({ row }) => {
    //     return (
    //       <Box>
    //         <Typography textAlign="center" variant="body2" fontWeight={500}>
    //           Weight: {formatNumber(row.total_weight / 1000)}Kg
    //         </Typography>
    //       </Box>
    //     );
    //   },
    // },
  ];

  const bulkCreateHandler = () => {
    const awbs = finalData.filter(fd => selectedRows.includes(fd.id)).map((R) => R?.proshipOrder?.[0]?.awbNumber)
    setIsLoading(true)
    postGeneric(apiConstants.GET_BULK_LABELS, {
      awbList: awbs
    }).then(({ data }) => {
      window.open(data.mergedPdf, true)
    }).catch(e => console.error(e))
      .finally(() => setIsLoading(false))
  };

  return (
    <div>
      {(isLoading || isFetching || loading) && <Loader />}
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <Paper sx={{ p: 2, boxShadow: 1, borderRadius: 0 }}>
            <FormProvider methods={methods} onSubmit={handleSubmit(applyPickupFilters)}>
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={2}>
                  <RHFTextField name="order_number" label="Order Number" variant="filled" />
                </Grid>
                {/* <Grid item xs={3} >
                  <RHFTextField name="awb_number" label="AWB Number" variant="filled" />
                </Grid> */}
                <Grid item xs={2}>
                  <RHFSelectField
                    label="Payment Mode"
                    name="payment_mode"
                    options={[{ label: "COD", value: 'COD' }, { label: "PREPAID", value: 'PREPAID' }]}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={2}>
                  <RHFSelectField
                    label="Whatapp Verified"
                    name="order_verification"
                    options={[{ label: "Yes", value: true }, { label: "No", value: 'No' }]}
                    width="100%"
                  />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="email" label="Customer Email" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="phone" label="Customer Phone" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="store_name" label="Store Name" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={2}>
                  <RHFTextField name="awbNumber" label="AWB Number" color="secondary" variant="filled" />
                </Grid>
                <Grid item xs={6}>
                  <Button type="submit" variant="contained">Filter</Button>
                  <Button sx={{ ml: 1 }} type="submit" variant="contained" color="error" onClick={() => {
                    reset()
                  }}>Reset</Button>
                </Grid>
              </Grid>
            </FormProvider>
          </Paper>
        </Grid>
        {
          selectedRows.length > 0 && (
            <Grid item xs={12}>
              <Stack
                direction='row'
                sx={{
                  mb: 1,
                  justifyContent: 'flex-end'
                }}>
                <LoadingButton
                  loading={loading}
                  onClick={bulkCreateHandler}
                  variant='contained'
                  startIcon={<CheckCircleIcon />}
                >
                  Download Labels
                </LoadingButton>
              </Stack>
            </Grid>
          )
        }
        <Grid item xs={12}>
          <ProzoDataGrid
            columns={columns}
            rows={finalData}
            sizePerPage={sizePerPage}
            setSizePerPage={setSizePerPage}
            setPage={setPage}
            page={page}
            checkboxSelection
            onSelectionModelChange={(_ids) => setSelectedRows(_ids)}
            pagination={true}
            hideFooterPagination={false}
            filterMode={"server"}
            rowCount={data?.count}
            getRowId={(row) => row.id}
          />
        </Grid>
      </Grid>

      <MUIModal
        open={orderItems ? true : false}
        title="Order Items"
        handleClose={() => setOrderItems(null)}
      >
        <ProzoDataGrid
          columns={[
            { field: "sku_id", headerName: "SKU", sortable: false, flex: 1 },
            { field: "title", headerName: "Name", sortable: false, flex: 1 },
            {
              field: "selling_price",
              headerName: "Price",
              sortable: false,
              flex: 1,
            },
            {
              field: "units",
              headerName: "Quantity",
              sortable: false,
              flex: 1,
            },
            {
              field: "tax",
              headerName: "Tax(%)",
              sortable: false,
              flex: 1,
              renderCell: ({ row }) => {
                return _.sumBy(row.taxlines, function (tax) {
                  return tax.rate * 100;
                });
              },
            },
            {
              field: "dimensions",
              headerName: "Dimensions/Weight",
              sortable: false,
              width: 150,
              renderCell: ({ row }) => {
                return (
                  <Box>
                    <Typography textAlign="center" variant="body2">
                      {[row.length, row.breath, row.height].join(" x ")}
                    </Typography>
                    <Typography
                      variant="body2"
                      textAlign="center"
                      fontWeight={500}
                    >
                      {formatNumber(row.weight / 1000)}Kg
                    </Typography>
                  </Box>
                );
              },
            },
          ]}
          rows={orderItems || []}
          boxShadow={0}
          hideFooterPagination
          rowCount={orderItems?.length || 0}
          getRowId={(row) => row.id}
        />
      </MUIModal>
    </div>
  );
}
