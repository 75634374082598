import { Card, CardContent, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";
import PageTitle from "../common/PageTitle";

const RateCard = () => {
    

    const price = [
        {
            "startWt": 0,
            "minWt": 0,
            "maxWt": 500,
            "price": 27,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 452
        },
        {
            "startWt": 500,
            "minWt": 500,
            "maxWt": 1000,
            "price": 54,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 72
        },
        {
            "startWt": 1000,
            "minWt": 1000,
            "maxWt": 1500,
            "price": 81,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 450
        },
        {
            "startWt": 1500,
            "minWt": 1500,
            "maxWt": 2000,
            "price": 108,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 476
        },
        {
            "startWt": 2000,
            "minWt": 2000,
            "maxWt": 2500,
            "price": 135,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 230
        },
        {
            "startWt": 2500,
            "minWt": 2500,
            "maxWt": 3000,
            "price": 162,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 10
        },
        {
            "startWt": 3000,
            "minWt": 3000,
            "maxWt": 3500,
            "price": 189,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 808
        },
        {
            "startWt": 3500,
            "minWt": 3500,
            "maxWt": 4000,
            "price": 216,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 380
        },
        {
            "startWt": 4000,
            "minWt": 4000,
            "maxWt": 4500,
            "price": 243,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 246
        },
        {
            "startWt": 4500,
            "minWt": 4500,
            "maxWt": 5000,
            "price": 270,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 151
        },
        {
            "startWt": 5000,
            "minWt": 5000,
            "maxWt": 5500,
            "price": 297,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 998
        },
        {
            "startWt": 5500,
            "minWt": 5500,
            "maxWt": 6000,
            "price": 324,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 285
        },
        {
            "startWt": 6000,
            "minWt": 6000,
            "maxWt": 6500,
            "price": 351,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 399
        },
        {
            "startWt": 6500,
            "minWt": 6500,
            "maxWt": 7000,
            "price": 378,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 855
        },
        {
            "startWt": 7000,
            "minWt": 7000,
            "maxWt": 7500,
            "price": 405,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 223
        },
        {
            "startWt": 7500,
            "minWt": 7500,
            "maxWt": 8000,
            "price": 432,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 687
        },
        {
            "startWt": 8000,
            "minWt": 8000,
            "maxWt": 8500,
            "price": 459,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 822
        },
        {
            "startWt": 8500,
            "minWt": 8500,
            "maxWt": 9000,
            "price": 486,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 466
        },
        {
            "startWt": 9000,
            "minWt": 9000,
            "maxWt": 9500,
            "price": 513,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 524
        },
        {
            "startWt": 9500,
            "minWt": 9500,
            "maxWt": 10000,
            "price": 540,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 865
        },
        {
            "startWt": 10000,
            "minWt": 10000,
            "maxWt": 11000,
            "price": 594,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 911
        },
        {
            "startWt": 11000,
            "minWt": 11000,
            "maxWt": 12000,
            "price": 648,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 944
        },
        {
            "startWt": 12000,
            "minWt": 12000,
            "maxWt": 13000,
            "price": 702,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 478
        },
        {
            "startWt": 13000,
            "minWt": 13000,
            "maxWt": 14000,
            "price": 756,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 892
        },
        {
            "startWt": 14000,
            "minWt": 14000,
            "maxWt": 15000,
            "price": 810,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 820
        },
        {
            "startWt": 15000,
            "minWt": 15000,
            "maxWt": 16000,
            "price": 864,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 996
        },
        {
            "startWt": 16000,
            "minWt": 16000,
            "maxWt": 17000,
            "price": 918,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 896
        },
        {
            "startWt": 17000,
            "minWt": 17000,
            "maxWt": 18000,
            "price": 972,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 541
        },
        {
            "startWt": 18000,
            "minWt": 18000,
            "maxWt": 19000,
            "price": 1026,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 236
        },
        {
            "startWt": 19000,
            "minWt": 19000,
            "maxWt": 20000,
            "price": 1080,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 988
        },
        {
            "startWt": 20000,
            "minWt": 20000,
            "maxWt": 21000,
            "price": 1134,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 674
        },
        {
            "startWt": 21000,
            "minWt": 21000,
            "maxWt": 22000,
            "price": 1188,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 788
        },
        {
            "startWt": 22000,
            "minWt": 22000,
            "maxWt": 23000,
            "price": 1242,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 54
        },
        {
            "startWt": 23000,
            "minWt": 23000,
            "maxWt": 24000,
            "price": 1296,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 813
        },
        {
            "startWt": 24000,
            "minWt": 24000,
            "maxWt": 25000,
            "price": 1350,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 773
        },
        {
            "startWt": 25000,
            "minWt": 25000,
            "maxWt": 26000,
            "price": 1404,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 438
        },
        {
            "startWt": 26000,
            "minWt": 26000,
            "maxWt": 27000,
            "price": 1458,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 897
        },
        {
            "startWt": 27000,
            "minWt": 27000,
            "maxWt": 28000,
            "price": 1512,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 307
        },
        {
            "startWt": 28000,
            "minWt": 28000,
            "maxWt": 29000,
            "price": 1566,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 709
        },
        {
            "startWt": 29000,
            "minWt": 29000,
            "maxWt": 30000,
            "price": 1620,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 754
        },
        {
            "startWt": 30000,
            "minWt": 30000,
            "maxWt": 31000,
            "price": 1674,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 237
        },
        {
            "startWt": 31000,
            "minWt": 31000,
            "maxWt": 32000,
            "price": 1728,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 835
        },
        {
            "startWt": 32000,
            "minWt": 32000,
            "maxWt": 33000,
            "price": 1782,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 944
        },
        {
            "startWt": 33000,
            "minWt": 33000,
            "maxWt": 34000,
            "price": 1836,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 383
        },
        {
            "startWt": 34000,
            "minWt": 34000,
            "maxWt": 35000,
            "price": 1890,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 929
        },
        {
            "startWt": 35000,
            "minWt": 35000,
            "maxWt": 36000,
            "price": 1944,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 318
        },
        {
            "startWt": 36000,
            "minWt": 36000,
            "maxWt": 37000,
            "price": 1998,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 73
        },
        {
            "startWt": 37000,
            "minWt": 37000,
            "maxWt": 38000,
            "price": 2052,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 340
        },
        {
            "startWt": 38000,
            "minWt": 38000,
            "maxWt": 39000,
            "price": 2106,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 548
        },
        {
            "startWt": 39000,
            "minWt": 39000,
            "maxWt": 40000,
            "price": 2160,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 136
        },
        {
            "startWt": 40000,
            "minWt": 40000,
            "maxWt": 41000,
            "price": 2214,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 262
        },
        {
            "startWt": 41000,
            "minWt": 41000,
            "maxWt": 42000,
            "price": 2268,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 945
        },
        {
            "startWt": 42000,
            "minWt": 42000,
            "maxWt": 43000,
            "price": 2322,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 398
        },
        {
            "startWt": 43000,
            "minWt": 43000,
            "maxWt": 44000,
            "price": 2376,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 377
        },
        {
            "startWt": 44000,
            "minWt": 44000,
            "maxWt": 45000,
            "price": 2430,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 320
        },
        {
            "startWt": 45000,
            "minWt": 45000,
            "maxWt": 46000,
            "price": 2484,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 506
        },
        {
            "startWt": 46000,
            "minWt": 46000,
            "maxWt": 47000,
            "price": 2538,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 10
        },
        {
            "startWt": 47000,
            "minWt": 47000,
            "maxWt": 48000,
            "price": 2592,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 592
        },
        {
            "startWt": 48000,
            "minWt": 48000,
            "maxWt": 49000,
            "price": 2646,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 812
        },
        {
            "startWt": 49000,
            "minWt": 49000,
            "maxWt": 50000,
            "price": 2700,
            "additionalUnit": 0,
            "additionalUnitRate": 0,
            "volumetricCoeff": 5000,
            "taxPercent": 18,
            "surchargePercent": 12,
            "docketCharge": 0,
            "invoicePercentCOD": 1.5,
            "codOperator": "MAX",
            "fixedCODCharges": 30,
            "dimensionChargeInclude": false,
            "dimensionCharge": 1,
            "otherCharge": 0,
            "handlineChargeStartWeight": 30000,
            "handlineCharges": 400,
            "isQCCheckCharged": false,
            "fovOwnerRisk": true,
            "chequeInvPectCODOp": "MAX",
            "draftInvPectCODOp": "MAX",
            "variance": 0,
            "cp": "LOCAL",
            "minTat": 1,
            "maxTat": 2,
            "tatUnit": "Days",
            "id": 883
        }
    ]

    const columns = [
        // {
        //     field: 'cp',
        //     headerName: 'Courier partner/ Zone Name',
        //     width: 320,
        // },
        {
            field: 'startWt',
            headerName: 'Start Weight (gm)',
            width: 150,
        },

        // {
        //     field: 'minWt',
        //     headerName: 'Min Weight (gm)',
        //     width: 150,
        // },
        {
            field: "maxWt",
            headerName: "Max weight (gm)",
            width: 150,

        },
        {
            field: 'price',
            headerName: 'Price',
            width: 100,
            renderCell: (col) => {
                return (col.row?.price ? ("₹ " + col.row?.price) : "-")
            }

        },
        {
            field: 'additionalUnit',
            headerName: 'Additional Unit(gm)',
            width: 100,
            renderCell: (col) => {
                return (col.row?.additionalUnit)
            }
        },
        {
            field: 'additionalUnitRate',
            headerName: 'Additional Unit Rate',
            width: 100,
            renderCell: (col) => {
                return (col.row?.additionalUnitRate)
            }
        },
        {
            field: 'volumetricCoeff',
            headerName: 'Volumetric Coefficient ',
            width: 100,
            renderCell: (col) => {
                return (col.row?.volumetricCoeff)
            }
        },
        {
            field: 'taxPercent',
            headerName: 'Tax',
            width: 100,
            renderCell: (col) => {
                return (col.row?.taxPercent ? (col.row?.taxPercent + " %") : "-")
            }
        },
        {
            field: 'surchargePercent',
            headerName: 'Surcharge %',
            width: 110,
            renderCell: (col) => {
                return (col.row?.surchargePercent ? (col.row?.surchargePercent + " %") : "-")
            }
        },
        {
            field: 'docketCharge',
            headerName: 'Docket Charge',
            width: 110,
            renderCell: (col) => {
                return (col.row?.docketCharge)
            }
        },
        {
            field: 'invoicePercentCOD',
            headerName: 'Invoice Percentage for COD %',
            width: 130,
            renderCell: (col) => {
                return (col.row?.invoicePercentCOD ? (col.row?.invoicePercentCOD + " %") : "-")

            }
        },
        {
            field: 'codOperator',
            headerName: 'COD Operator',
            width: 110,
            renderCell: (col) => {
                return (col.row?.codOperator)
            }
        },
        {
            field: 'withQCCharge',
            headerName: 'RVP With QC ',
            width: 120,
            renderCell: (col) => {
                return (col.row?.withQCCharge ? ("₹ " + col.row?.withQCCharge) : "-")
            }
        },
        {
            field: 'withoutQCCharge',
            headerName: 'RVP Without QC',
            width: 150,
            renderCell: (col) => {
                return (col.row?.withoutQCCharge ? ("₹ " + col.row?.withoutQCCharge) : "-")
            }
        },

        {
            field: 'fixedCODCharges',
            headerName: 'COD Charges',
            width: 130,
            renderCell: (col) => {
                return (col.row?.fixedCODCharges ? ("₹" + col.row?.fixedCODCharges) : "-")

            }
        },
        {
            field: 'minTat',
            headerName: 'Min TAT',
            width: 100,

        },
        {
            field: 'maxTat',
            headerName: 'Max TAT',
            width: 100,

        },
        // {
        //     field: 'tatUnit',
        //     headerName: 'TAT Unit',
        //     width: 100,

        // },
        {
            field: 'rtoPercentOfForward',
            headerName: 'RTO (Forward %)',
            width: 100,
            renderCell: (col) => {
                return (col.row?.rtoPercentOfForward ? (col.row?.rtoPercentOfForward + " %") : "-")
            }
        },
    ]

    return (
        <div id="main-content" className="container-fluid">
            <PageTitle>Rate Card</PageTitle>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Card>
                        <CardContent>
                            {price.length > 0 &&
                                <DataGrid
                                    columns={columns}
                                    getRowId={(row) => row?.id}
                                    rows={price ? price : []}
                                    autoHeight={true}
                                    hideFooterPagination={true}
                                />}
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div >
    );
}


export default RateCard