import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import DateRangePicker from '../../components/DateRangePicker';



export default function RHFDateRangePickerField2({ label, maxDate, fromDateName = "fromDate", toDateName = "toDate", ...props }) {
    const { control, setValue, watch } = useFormContext();
    const fromDate = watch(fromDateName);
    const toDate = watch(toDateName);

    return (
        <React.Fragment>
            <DateRangePicker
                showTextBox={true}
                label={label}
                maxDate={maxDate}
                defaultRange={fromDate && toDate ? [{ startDate: fromDate, endDate: toDate, key: 'selection' }] : []}
                onChange={(range) => {
                    if (range.length > 0) {
                        setValue(fromDateName, range[0].startDate);
                        setValue(toDateName, range[0].endDate);
                    }
                }}
                InputLabelProps={{ style: { fontSize: 14 } }}
                {...props}
            />
            <Controller
                name={fromDateName}
                control={control}
                render={({ field }) => (
                    <input {...field} type="hidden" value={typeof field.value === 'number' && field.value === 0 ? '' : field.value} />
                )}
            />
            <Controller
                name={toDateName}
                control={control}
                render={({ field }) => (
                    <input {...field} type="hidden" value={typeof field.value === 'number' && field.value === 0 ? '' : field.value} />
                )}
            />
        </React.Fragment>
    );
}
