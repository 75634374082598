import { styled } from "@mui/material/styles";
import { Card, Container, Grid, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useResponsive from "./useResponsive";

import { useForm } from "react-hook-form";
import {
  FormProvider,
  RHFCheckbox,
  RHFSelectField,
  RHFTextField,
} from "../../common/hook-form";
import { useSnackbar } from "notistack";
import { apiConstants, postGeneric } from "../../common/constants";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import stateObj from "../../helpers/states"

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "flex",
    background: "#F9FAFB",
  },
}));

const HeaderStyle = styled("header")(({ theme }) => ({
  top: 0,
  zIndex: 9,
  lineHeight: 0,
  width: "100%",
  display: "flex",
  alignItems: "center",
  position: "absolute",
  padding: theme.spacing(3),
  justifyContent: "space-between",
  [theme.breakpoints.up("md")]: {
    alignItems: "flex-start",
    padding: theme.spacing(7, 5, 0, 7),
  },
}));

const SectionStyle = styled(Card)(({ theme }) => ({
  width: "100%",
  maxWidth: 464,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  margin: theme.spacing(2, 0, 2, 2),
}));

const ContentStyle = styled("div")(({ theme }) => ({
  margin: "auto",
  minHeight: "100vh",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  padding: theme.spacing(12, 0),
}));

// ----------------------------------------------------------------------

export default function Login() {
  const mdUp = useResponsive("up", "md");
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();


  const phoneRegExp = /^\d{10}$/;

  const method = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        name: Yup.string().required("Name is required"),
        email: Yup.string().required("Email address is required"),
        contact_no: Yup.string()
          .matches(phoneRegExp, { message: 'Phone Number must be exactly 10 digits', excludeEmptyString: true })
          .min(10, 'Phone Number must be at least 10 digits')
          .required("Phone Number is required!"),
        // contact_no: Yup.string()
        //   .test('valid-contact-no', 'Invalid contact number', value => {
        //     if (!value) {
        //       return false; // Empty value
        //     }

        //     const regex = /^([+]\d{2})?\d{10}$/;
        //     return regex.test(value) || value.length !== 10;
        //   })
        //   .required("Contact number is required")
        //   .test('length', 'Please enter a 10 digit contact number', value => {
        //     return !value || value.length === 10;
        //   })
        //   .test('valid', 'Please enter a valid contact number', value => {
        //     if (!value || value.length !== 10) {
        //       return true; // Skip validation for non-10 digit values
        //     }

        //     const regex = /^([+]\d{2})?\d{10}$/;
        //     return regex.test(value);
        //   }),
        address: Yup.string().required("Address is required"),
        pincode: Yup.string()
          .test('valid-pincode', 'Invalid pincode', value => {
            if (!value) {
              return false; // Empty value
            }

            const regex = /^\d{6}$/; // 6-digit number
            return regex.test(value) || value.length !== 6;
          })
          .required("Pincode is a mandatory field")
          .test('length', 'Please enter a 6 digit pincode', value => {
            return !value || value.length === 6;
          })
          .test('valid', 'Please enter a valid pincode', value => {
            if (!value || value.length !== 6) {
              return true; // Skip validation for non-6 digit values
            }

            const regex = /^\d{6}$/; // 6-digit number
            return regex.test(value);
          }),
        city: Yup.string().required("City is required"),
        state: Yup.string().required("State is required"),
        country: Yup.string().required("Country is required"),
        toc: Yup.bool()
          .oneOf([true], "Please accept the terms & conditions")
          .required("Please accept the terms & conditions"),
      })
    ),
    defaultValues: {
      name: "",
      email: "",
      contact_no: "",
      address: "",
      pincode: "",
      city: "",
      state: "",
      companyName: "",
      country: "India",
    },
  });

  const { handleSubmit } = method;

  const onSubmit = (data) => {
    // navigate("/otp")
    postGeneric(apiConstants.SIGNUP, data)
      .then((response) => {
        enqueueSnackbar(
          "OTP Sent! Please check your mobile number for otp credentials",
          { variant: "success" }
        );
        navigate("/otp", { state: response.data });
      })
      .catch((error) => {
        enqueueSnackbar(
          error?.response?.data?.message || "Oops Something went wrong!",
          { variant: "error" }
        );
      });
  };

  return (
    <RootStyle>
      <HeaderStyle>
        <img
          src="/img/prozo-logo2.svg"
          className="logo"
          height="60"
          alt="logo"
        />
      </HeaderStyle>

      {mdUp && (
        <SectionStyle>
          <Typography
            textTransform="uppercase"
            fontWeight="bold"
            variant="h5"
            sx={{ px: 5 }}
          >
            Prozo Logistics Network (PLN)
          </Typography>
          <Typography variant="body2" sx={{ px: 5, mt: 1 }}>
            One place for all your shipping needs; FTL, PTL & D2C Freight
            including hyperlocal freight!
          </Typography>
        </SectionStyle>
      )}

      <Container maxWidth="sm">
        <ContentStyle>
          <FormProvider methods={method} onSubmit={handleSubmit(onSubmit)}>
            <Typography variant="h4">
              Please enter your details to sign up
            </Typography>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12}>
                <RHFTextField name="name" mandatory="true" label="Registered Business Name" variant="filled" />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextField name="email" mandatory="true" label="Email" variant="filled" />
              </Grid>
              <Grid item xs={12} md={6}>
                <RHFTextField
                  mandatory="true"
                  name="contact_no"
                  label="Enter 10 digit Contact Number"
                  variant="filled"
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <RHFTextField name="address"
                  mandatory="true"
                  label="Billing/Registered Address" variant="filled" />
              </Grid>
              <Grid item xs={12} md={12}>
                <RHFTextField name="companyName"
                  label="Company Name" variant="filled" />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFTextField name="city" mandatory="true" label="City" variant="filled" />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFSelectField name="state" mandatory="true" label="State" options={stateObj} width="100%" />
              </Grid>
              <Grid item xs={12} md={4}>
                <RHFTextField name="pincode" mandatory="true" label="Pincode" variant="filled" />
              </Grid>
              <Grid item xs={12} md={12}>
                <RHFCheckbox
                  name="toc"
                  mandatory="true"
                  label={(
                    <>
                      I have read and agree to the <Link to="/toc" target="_blank"><u>Terms & Conditions</u></Link>
                    </>
                  )}
                />
                {method?.formState?.errors?.toc && (
                  <p
                    style={{ color: "red" }}
                  >{`${method?.formState?.errors?.toc?.message}`}</p>
                )}
              </Grid>
              <Grid item xs={12} md={12}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  size="large"
                >
                  Submit
                </LoadingButton>
              </Grid>
              <Grid item xs={12} md={12}>
                <Typography textAlign="center">
                  Already have an account?{" "}
                  <Link to="/login">
                    <u>Login</u>
                  </Link>
                </Typography>
              </Grid>
            </Grid>
          </FormProvider>
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
