import React, { useState } from 'react';
import withLayout from '../../../hocs/Layout';
import { FormProvider, RHFFileField } from '../../../common/hook-form';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { Box } from '@mui/system';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { apiConstants, postMultipart } from '../../../common/constants';
import { toast } from "react-toastify";
import { Button, Typography } from '@mui/material';
import { DownloadOutlined, UploadFileOutlined } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LabelDownloadBulkB2B from './LabelB2BDownload';

const BulkDownloadLabelsB2B = () => {
    const methodsBulkUpload = useForm({
        resolver: yupResolver(Yup.object().shape({
            orderFile: Yup.mixed().required("Please Select a File"),
        })),
        defaultValues: {
            orderFile: null
        }
    });
    const { handleSubmit: handleSubmitBulkUpload, reset } = methodsBulkUpload;
    const [fileLink, setFileLink] = useState([])
    const [isUploadingFile, setIsUploadingFile] = useState(false)
    const [zipLink, setZipLink] = useState([])
    const [mergedPdf, setMergedPdf] = useState([])
    // const { formState: { errors } } = useForm();
    // const [bulkUpload, setBulkUploas] = useState(false)

    const onSubmitBulkUpload = async (data) => {
        setIsUploadingFile(true)
        try {
            let form = new FormData()
            form.append('file', data.orderFile[0])
            await postMultipart(apiConstants.BULK_DOWNLOAD_LABELS, form).then(response => {
                if (response.data && response.data['excel'] && response.data['excel'].startsWith('https')) {
                    setFileLink(response.data['excel']);
                    setZipLink(response.data['zipFile'])
                    setMergedPdf(response.data['mergedPdf'])
                    toast("Successfully Uploaded!", { type: 'success' })
                    reset({
                        orderFile: null
                    })
                }
                else {
                    toast(response?.data[3962], { type: 'error' })
                }
            }).catch(err => { console.error(err); setIsUploadingFile(false); toast(err?.response?.data?.message || "Error uploading file", { type: 'error' }) });
        } catch (err) {
            const message = err?.response?.data?.message || err.message
            toast(message || "Error uploading file", { type: 'error' })
        }

        setIsUploadingFile(false)
    }

    return (
        <>
            <PageTitle>Shipping Labels Download B2B</PageTitle>
            <div id="main-content" className="container-fluid">
                <Box width={1} sx={{ backgroundColor: '#fff', boxShadow: 1, p: 3, mb: '100px' }}>
                    <LabelDownloadBulkB2B />
                    <Box sx={{ mt: 2 }} />
                    <FormProvider methods={methodsBulkUpload} onSubmit={handleSubmitBulkUpload(onSubmitBulkUpload)}>
                        <RHFFileField name="orderFile" accept={['xlsx']} />
                        <Box sx={{ my: 2 }} />
                        <LoadingButton
                            startIcon={<FileUploadIcon />}
                            type="submit"
                            loading={isUploadingFile}
                            variant="contained"
                            color="warning"
                            size="medium">
                            Upload
                        </LoadingButton>
                        {/* <a className="btn btn-link btn-download" style={{ 'marginLeft': '16px' }} href="" download="Order Upload Sample"><img src="img/download.svg" width="22" alt="" /></a> */}
                        {fileLink?.length === 0 &&
                            <Button variant="contained" className="btn-download" style={{ marginLeft: '16px', textDecoration: 'none' }} href={"https://djslb35m04okm.cloudfront.net/generate_labels.csv"}
                                download="Order Upload Sample"
                                startIcon={<UploadFileOutlined style={{ fontSize: 22 }} />}
                            >
                                Sample Download
                            </Button>
                        }
                        {fileLink?.length > 0 &&
                            // <a className="btn btn-link btn-download" style={{ 'marginLeft': '16px' }} href={fileLink} download="Order Upload Sample"><img src="img/download.svg" width="22" alt="" />Download Labels Excel </a>
                            <Button variant="contained" className="btn-download" style={{ marginLeft: '16px', textDecoration: 'none' }} href={fileLink}
                                download="Download Excel"
                                startIcon={<DownloadOutlined style={{ fontSize: 22 }} />}
                            >
                                Download Excel
                            </Button>

                        }
                        {zipLink?.length > 0 &&
                            // <a className="btn btn-link btn-download" style={{ 'marginLeft': '16px' }} href={zipLink} download="Order Upload Sample"><img src="img/download.svg" width="22" alt="" />Download Zip file </a>
                            <Button variant="contained" className="btn-download" style={{ marginLeft: '16px', textDecoration: 'none' }} href={zipLink}
                                download="Download Zip"
                                startIcon={<DownloadOutlined style={{ fontSize: 22 }} />}
                            >
                                Download Zip
                            </Button>
                        }
                        {mergedPdf?.length > 0 &&
                            // <a className="btn btn-link btn-download" style={{ 'marginLeft': '16px' }} href={zipLink} download="Order Upload Sample"><img src="img/download.svg" width="22" alt="" />Download Zip file </a>
                            <Button variant="contained" className="btn-download" style={{ marginLeft: '16px', textDecoration: 'none' }} href={mergedPdf}
                                download="Download PDF"
                                target="_blank"
                                startIcon={<DownloadOutlined style={{ fontSize: 22 }} />}
                            >
                                Download Merged PDF
                            </Button>
                        }
                        <Typography component='span' color='error' variant='body1'> You can only upload up to 500 AWBs at once.</Typography>
                    </FormProvider>
                </Box>
            </div>
        </>
    )
}
export default withLayout(BulkDownloadLabelsB2B);