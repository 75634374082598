import React from "react"
import { useGetSelectedDateRange } from "../../../../common/contexts/DateSelector"
import { useGetSelectedFilters } from "../../../../common/contexts/Filters"
import _ from 'lodash'
import { delivered, allZones, deliveryAgeingBucket, flattenResponseData, formatDate, getYYYYMMDD, numberPrecision, getShipmentInfo, placed, getDiffByIgnoringMidNight, tatBuckets } from "../../../../helpers/UtilityHelper"
import { addDays, eachDayOfInterval, eachMonthOfInterval, eachWeekOfInterval, getISOWeek, isWithinInterval } from "date-fns"
import { getMerchant } from "../../../../helpers/ReduxHelpers"
import { useGenericPostRequestQuery } from "../../../../redux/commonRTK"
import { apiConstants } from "../../../../common/constants"
import { addWeeks } from "date-fns"
import { addMonths } from "date-fns"

const weekStartsOn = 1

export default function useForwardDeliveries(groupOn, shipmentType) {
    const [isCalc, setIsCalc] = React.useState(true)

    const { id } = getMerchant()
    const { from_date, to_date } = useGetSelectedDateRange()
    const filters = useGetSelectedFilters()
    // const { userInfo } = useSelector((state) => state.user)
    // const shipmentInfo = getShipmentInfo(userInfo, shipmentType);

    const merchant = getMerchant();
    const shipmentInfo = getShipmentInfo(merchant, shipmentType);  
  

    const skip = !from_date || !to_date
    const request = { from_date, to_date, merchant_list: id, orderType_list: 'FORWARD', shipmentType_list: shipmentInfo }

    const { data, isLoading: isLoadingReg, isFetching: isFetchingReg } = useGenericPostRequestQuery({
        url: apiConstants.ALL_AWB_REGISTERED_BY_DATE_RANGE,
        data: request
    }, { skip })
    const allRegistedShipments = _.filter(flattenResponseData(data || []), filters)

    const allDeliveredShipments = React.useMemo(() => {
        return _.filter(allRegistedShipments, function (item) {
            return delivered.includes(item.orderStatus)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allRegistedShipments?.length])

    const deliveryPayload = { merchantId: id, awbNumbers: allDeliveredShipments?.map(r => r.awbNumber) }
    const genericRequest = { url: apiConstants.get_specific_data_delivery, type: 'POST', data: [deliveryPayload] }
    const { data: deliveryDetails, isLoading: isLoadingDelivered, isFetching: isFetchingDelivered } = useGenericPostRequestQuery(genericRequest, { skip: allDeliveredShipments.length === 0 })


    const allDeliveredShipmentsWithDeliveryData = React.useMemo(() => {

        if (!allDeliveredShipments || !deliveryDetails) return []

        const deliveryDetailsMap = deliveryDetails.reduce((map, item) => {
            map.set(item.awbNumber, item)
            return map
        }, new Map())

        return allDeliveredShipments.map((shipment) => {

            if (deliveryDetailsMap.has(shipment.awbNumber)) {
                return { ...shipment, ...deliveryDetailsMap.get(shipment.awbNumber) }
            }

            return shipment
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allDeliveredShipments?.length, deliveryDetails?.length])

    const result = React.useMemo(() => {

        if (isFetchingReg || isLoadingReg || isLoadingDelivered || isFetchingDelivered) {
            setIsCalc(true)
            return;
        }

        const allRegisteredCount = allDeliveredShipmentsWithDeliveryData?.length

        const pickedUpAll = _.reject(allRegistedShipments, (item) =>
            _.includes(['CANCELLED_ORDER', ...placed], item.orderStatus)
        );

        const pickedUp = _.reject(pickedUpAll, (item) =>
            _.includes('REVERSE SHIPMENT', item.orderType)
        );

        const forwardDeliveredPercentage = (allRegisteredCount / pickedUp?.length) * 100

        let intervalArr = [],
            courierWiseDeliveryAttempt = [],
            courierWiseDelivered = [],
            zoneWiseDelivered = [],
            zoneWiseDeliveryAttempt = [],
            allDataCount = allRegisteredCount,
            uniqCourierIds = _(allDeliveredShipmentsWithDeliveryData).uniqBy('courier').map('courier').value(),
            summary = { totalDelivered: allRegisteredCount, forwardDeliveredPercentage },
            tatSplit = [],
            tatSplitFirstDeliveryAttempt = [],
            deliveryOutTat = [],
            deliveryAttemptSplit = [];

        if (groupOn === 'DOD') {
            const endDate = addDays(new Date(to_date), 1)
            intervalArr = eachDayOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'WOW') {
            const endDate = addWeeks(new Date(to_date), 1)
            intervalArr = eachWeekOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        } else if (groupOn === 'MOM') {
            const endDate = addMonths(new Date(to_date), 1)
            intervalArr = eachMonthOfInterval({ start: new Date(from_date), end: endDate }, { weekStartsOn })
        }

        const allRegisteredDataGroupedByDate = _.groupBy(allDeliveredShipmentsWithDeliveryData, function (item) {
            return getYYYYMMDD(item?.awbRegisteredDate || new Date())
        })

        const deliveryAttemptInOutTat = _(allDeliveredShipmentsWithDeliveryData).groupBy(function (item) {
            return item.firstAttemptWithinSLA ? "IN" : "OUT"
        }).value()

        const deliveredInOutTat = _(allDeliveredShipmentsWithDeliveryData).groupBy(function (item) {
            return item.isBreached ? "OUT" : "IN"
        }).value()

        summary.deliveredInTatCount = deliveredInOutTat?.IN?.length || 0
        summary.deliveredOutTatCount = deliveredInOutTat?.OUT?.length || 0
        summary.deliveredInTatPercentage = (summary.deliveredInTatCount / summary.totalDelivered) * 100
        summary.deliveredOutTatPercentage = (summary.deliveredOutTatCount / summary.totalDelivered) * 100

        summary.deliveryAttemptInTatCount = deliveryAttemptInOutTat?.IN?.length || 0
        summary.deliveryAttemptOutTatCount = deliveryAttemptInOutTat?.OUT?.length || 0
        summary.deliveryAttemptInTatPercentage = (summary.deliveryAttemptInTatCount / summary.totalDelivered) * 100
        summary.deliveryAttemptOutTatPercentage = (summary.deliveryAttemptOutTatCount / summary.totalDelivered) * 100

        const groupedByTat = _(allDeliveredShipmentsWithDeliveryData)
            .groupBy(function (item) {
                if (!item.isBreached) {
                    return 'zero'
                } else if (item.deliveryBreachedAgeingDays === 1) {
                    return 'one'
                } else if ((item.deliveryBreachedAgeingDays > 1) && (item.deliveryBreachedAgeingDays <= 4)) {
                    return 'two'
                } else {
                    return 'three'
                }

            }).transform(function (res, items, key) {
                res[key] = items.length
            }).value()

        for (let index = 0; index < intervalArr.length - 1; index++) {
            const start = intervalArr[index]
            let end = intervalArr[index + 1] ? intervalArr[index + 1] : new Date()

            let name = null;
            if (groupOn === 'DOD') {
                name = formatDate(start, 'dd MMM')
            } else if (groupOn === 'WOW') {
                name = `W${getISOWeek(start)}(${formatDate(start, 'dd MMM')}-${formatDate(end, 'dd MMM')})`
            } else {
                name = formatDate(start, 'MMM')
            }

            const allRegisteredDatesBetween = _.filter(Object.keys(allRegisteredDataGroupedByDate), function (date) {
                return isWithinInterval(new Date(date), { start, end })
            })

            const allRegisteredDataBetweenDate = allRegisteredDatesBetween.map(key => allRegisteredDataGroupedByDate[key]).flat()

            const tatRow = calcTatSplit(allRegisteredDataBetweenDate, allRegisteredDataBetweenDate.length);
            tatSplit.push({ ...tatRow, name });

            const tatRowDeliveryAttempt = calcTatSplit(allRegisteredDataBetweenDate, allRegisteredDataBetweenDate.length, true);
            tatSplitFirstDeliveryAttempt.push({ ...tatRowDeliveryAttempt, name });

            const attempt = courierWiseSplit(allDataCount, allRegisteredDataBetweenDate, uniqCourierIds, 'firstAttemptWithinSLA')
            const delivered = courierWiseSplit(allDataCount, allRegisteredDataBetweenDate, uniqCourierIds, 'isBreached')

            const zoneWise = zoneWiseSplit(allDataCount, allRegisteredDataBetweenDate, 'isBreached')
            const zoneWiseAttempt = zoneWiseSplit(allDataCount, allRegisteredDataBetweenDate, 'firstAttemptWithinSLA')

            const breachedBetweenDate = _(allRegisteredDataBetweenDate).filter({ isBreached: true }).value()
            const groupedByDeliveryOutTat = _(breachedBetweenDate).groupBy(function (item) {
                const breachedDay = item?.deliveryBreachedAgeingDays || 0
                if (_.inRange(breachedDay, 0, 2)) {
                    return '0-1'
                } else if (_.inRange(breachedDay, 2, 5)) {
                    return '2-4'
                } else if (_.inRange(breachedDay, 5, 8)) {
                    return '5-7'
                } else if (_.inRange(breachedDay, 8, 16)) {
                    return '8-15'
                } else {
                    return '15+'
                }
            }).value()

            const deliveryAgeing = _(deliveryAgeingBucket)
                .keys()
                .transform(function (res, key) {
                    const counts = groupedByDeliveryOutTat[key]?.length || 0
                    const percentage = Number(numberPrecision((counts / allDataCount) * 100))
                    res[`${key}_count`] = counts
                    res[`${key}_percentage`] = percentage
                }, {})
                .value()

            const avgDelaySum = _.sumBy(breachedBetweenDate, 'deliveryBreachedAgeingDays')
            const avgDelay = Number(numberPrecision((avgDelaySum / breachedBetweenDate.length) || 0))

            const attemptSplit = _(allRegisteredDataBetweenDate)
                .groupBy('deliveryAttemptCount')
                .transform(function (res, orderList, attemptCount) {
                    const ordersCount = orderList.length;
                    let keyName;
                    if (attemptCount === '1') {
                        keyName = 'firstAttempt'
                    } else if (attemptCount === '2') {
                        keyName = 'secondAttempt'
                    } else if (attemptCount === '3') {
                        keyName = 'thirdAttempt'
                    } else {
                        keyName = 'moreThanThreeAttempt'
                    }
                    const percentage = (ordersCount / allRegisteredDataBetweenDate.length) * 100;
                    res[keyName] = ordersCount;
                    res[`${keyName}_Percentage`] = percentage;
                }).value();

            deliveryAttemptSplit.push({ name, ...attemptSplit });
            deliveryOutTat.push({ name, avgDelay, ...deliveryAgeing })
            courierWiseDeliveryAttempt.push({ name, ...attempt })
            courierWiseDelivered.push({ name, ...delivered })
            zoneWiseDelivered.push({ name, ...zoneWise })
            zoneWiseDeliveryAttempt.push({ name, ...zoneWiseAttempt })
        }

        const groupedByTatAndState = _(allDeliveredShipmentsWithDeliveryData)
            .groupBy((item) => _.startCase(item.deliveryState))
            .map(function (items, name) {
                const total = items.length;
                const calcData = calcTatSplit(items, total);

                return { name, ...calcData, total }
            }).value()

        const results = {
            tatSplit,
            tatSplitFirstDeliveryAttempt,
            groupedByTatAndState,
            allRegisteredData: allDeliveredShipmentsWithDeliveryData,
            deliveryOutTat,
            summary,
            courierWiseDeliveryAttempt,
            groupedByTat,
            uniqCourierIds,
            courierWiseDelivered,
            zoneWiseDelivered,
            zoneWiseDeliveryAttempt,
            deliveryAttemptSplit
        }

        setIsCalc(false)
        return results;

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [from_date, to_date, allDeliveredShipmentsWithDeliveryData?.length, allRegistedShipments?.length, isLoadingReg, isFetchingReg, isLoadingDelivered, isFetchingDelivered, groupOn])

    if (isCalc) return { isLoading: isCalc }
    return { data: result, isLoading: isCalc };

}

function calcTatSplit(allRegisteredDataBetweenDate, allDataCount, calculateOnFirstAttempt = false) {

    const groupedTat = _(allRegisteredDataBetweenDate)
        .groupBy(function (item) {

            let deliveryBreachedAgeingDays;
            if (calculateOnFirstAttempt) {
                deliveryBreachedAgeingDays = getDiffByIgnoringMidNight(item.estimatedDeliveryDate, item.firstDeliveryAttemptTimestamp);
            } else {
                deliveryBreachedAgeingDays = item.deliveryBreachedAgeingDays;
            }

            return getTatKey(deliveryBreachedAgeingDays);

        }).value()

    const tatRow = tatBuckets.reduce((acc, key) => {
        const items = groupedTat[key] || [];
        const counts = items.length;
        const percentage = Number(numberPrecision((counts / allDataCount) * 100));
        acc[`${key}_count`] = counts;
        acc[`${key}_percentage`] = Math.round(percentage);
        return acc;
    }, {})

    return tatRow;
}

function getTatKey(deliveryBreachedAgeingDays) {
    if (deliveryBreachedAgeingDays <= 0) {
        return "In TAT";
    } else if (deliveryBreachedAgeingDays < 2) {
        return "TAT +1";
    } else if (deliveryBreachedAgeingDays < 3) {
        return "TAT +2";
    } else if (deliveryBreachedAgeingDays < 4) {
        return "TAT +3";
    }

    return 'TAT >3';
}

function courierWiseSplit(allDataCount, allRegisteredDataBetweenDate, uniqCourierIds, key) {

    const groupedByInOutTatData = _(allRegisteredDataBetweenDate)
        .groupBy(function (item) {
            if (key === 'firstAttemptWithinSLA') {
                return item[key] ? 'IN' : 'OUT'
            } else {
                return item[key] ? 'OUT' : 'IN'
            }
        }).value()

    const row = {
        totalInTat: groupedByInOutTatData?.IN?.length || 0,
        totalOutTat: groupedByInOutTatData?.OUT?.length || 0
    };

    const k = row.totalInTat + row.totalOutTat;
    row.totalInTatPercentage = Number(numberPrecision((row.totalInTat / k) * 100))
    row.totalOutTatPercentage = Number(numberPrecision((row.totalOutTat / k) * 100))

    const outTatGroupedByCourier = _(groupedByInOutTatData?.OUT || []).groupBy('courier').value()
    const inTatGroupedByCourier = _(groupedByInOutTatData?.IN || []).groupBy('courier').value()

    for (const courierId of uniqCourierIds) {
        const courierCountOutTat = outTatGroupedByCourier[courierId]?.length || 0
        const courierCountInTat = inTatGroupedByCourier[courierId]?.length || 0
        const totalCourierCount = courierCountOutTat + courierCountInTat

        const percentageOutTat = Number(numberPrecision((courierCountOutTat / totalCourierCount) * 100))
        row[`c_${courierId}_count_out`] = courierCountOutTat
        row[`c_${courierId}_perc_out`] = percentageOutTat

        const percentageInTat = Number(numberPrecision((courierCountInTat / totalCourierCount) * 100))
        row[`c_${courierId}_count_in`] = courierCountInTat
        row[`c_${courierId}_perc_in`] = percentageInTat
    }

    return row;
}

function zoneWiseSplit(allDataCount, allRegisteredDataBetweenDate, key) {

    const groupedByInOutTatData = _(allRegisteredDataBetweenDate)
        .groupBy(function (item) {
            if (key === 'firstAttemptWithinSLA') {
                return item[key] ? 'IN' : 'OUT'
            } else {
                return item[key] ? 'OUT' : 'IN'
            }
        }).value()

    const row = {
        totalInTat: groupedByInOutTatData?.IN?.length || 0,
        totalOutTat: groupedByInOutTatData?.OUT?.length || 0
    };

    const k = row.totalInTat + row.totalOutTat
    row.totalInTatPercentage = Number(numberPrecision((row.totalInTat / k) * 100))
    row.totalOutTatPercentage = Number(numberPrecision((row.totalOutTat / k) * 100))

    const outTatGroupedByCourier = _(groupedByInOutTatData?.OUT || []).groupBy('merchantZone').value()
    const inTatGroupedByCourier = _(groupedByInOutTatData?.IN || []).groupBy('merchantZone').value()

    for (const z of allZones) {
        const courierCountOutTat = outTatGroupedByCourier[z]?.length || 0
        const courierCountInTat = inTatGroupedByCourier[z]?.length || 0
        const totalCourierCount = courierCountOutTat + courierCountInTat

        const percentageOutTat = Number(numberPrecision((courierCountOutTat / totalCourierCount) * 100))
        row[`c_${z}_count_out`] = courierCountOutTat
        row[`c_${z}_perc_out`] = percentageOutTat

        const percentageInTat = Number(numberPrecision((courierCountInTat / totalCourierCount) * 100))
        row[`c_${z}_count_in`] = courierCountInTat
        row[`c_${z}_perc_in`] = percentageInTat
    }

    return row;
}