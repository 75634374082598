import React from "react";
import { Card, CardContent, CardHeader, Grid, ToggleButton, ToggleButtonGroup } from "@mui/material";
import { formatNumber, numberPrecision } from "../../../../helpers/UtilityHelper";
import BarChartIcon from '@mui/icons-material/BarChart';
import TableChartIcon from '@mui/icons-material/TableChart';
import { Box } from "@mui/system";
import _ from 'lodash'
import InfoBox from "../../../../common/InfoBox";
import { StackedBarChart } from "../../../charts/BarChart";
import MaterialTable from "../../../../common/MaterialTable";

export default function DeliveryAttempt({ data }) {
    const [view, setView] = React.useState("chart");
    const tatColors = {
        firstAttempt: 'rgb(0, 197, 153)',
        secondAttempt: 'rgb(0, 167, 235)',
        thirdAttempt: 'rgba(240, 180, 27, 0.9)',
        moreThanThreeAttempt: 'red',
    };
    const valueRender = (key) => {
        return (value) => {
            const keyValue = value[key];
            if (!keyValue) return '-';
            const percetange = value[`${key}_Percentage`];
            return `${keyValue} (${numberPrecision(percetange, 0)}%)`
        }
    }
    const tableColumns = [
        {
            label: 'Attempt',
            value: 'name',
            align: 'left'
        },
        {
            label: '1st Attempt',
            value: valueRender('firstAttempt'),
            align: 'left'
        },
        {
            label: '2nd Attempt',
            value: valueRender('secondAttempt'),
            align: 'left'
        },
        {
            label: '3rd Attempt',
            value: valueRender('thirdAttempt'),
            align: 'left'
        },
        {
            label: '3+ Attempt',
            value: valueRender('moreThanThreeAttempt'),
            align: 'left',
        },
    ]
    const chartOptions = {
        layout: 'horizontal',
        xaxis: 'name',
        labelY: 'Shipments',
        stackId: 'stackedBar',
        data: [
            {
                dataKey: 'firstAttempt',
                label: '1st Attempt',
                chartType: 'bar',
                fill: tatColors.firstAttempt
            },
            {
                dataKey: 'secondAttempt',
                label: '2nd Attempt',
                chartType: 'bar',
                fill: tatColors.secondAttempt
            },
            {
                dataKey: 'thirdAttempt',
                label: '3rd Attempt',
                chartType: 'bar',
                fill: tatColors.thirdAttempt
            },
            {
                dataKey: 'moreThanThreeAttempt',
                label: '3+ Attempt',
                chartType: 'bar',
                fill: tatColors.moreThanThreeAttempt
            }
        ]
    }
    const totalFirstAttempt = isNaN(_.sumBy(data, 'firstAttempt'))
        ? 0
        : _.sumBy(data, 'firstAttempt');

    const totalSecondAttempt = isNaN(_.sumBy(data, 'secondAttempt'))
        ? 0
        : _.sumBy(data, 'secondAttempt');

    const totalThirdAttempt = isNaN(_.sumBy(data, 'thirdAttempt'))
        ? 0
        : _.sumBy(data, 'thirdAttempt');

    const totalMoreThanThreeAttempt = isNaN(_.sumBy(data, 'moreThanThreeAttempt'))
        ? 0
        : _.sumBy(data, 'moreThanThreeAttempt');

    const total = totalFirstAttempt + totalSecondAttempt + totalThirdAttempt + totalMoreThanThreeAttempt;
    const totalFirstAttemptPercentage = totalFirstAttempt / total * 100;
    const totalSecondAttemptPercentage = totalSecondAttempt / total * 100;
    const totalThirdAttemptPercentage = totalThirdAttempt / total * 100;
    const totalMoreThanThreeAttemptPercentage = totalMoreThanThreeAttempt / total * 100;
    const summary = [
        { header: '1st Attempt', value: `${formatNumber(totalFirstAttempt)}(${numberPrecision(totalFirstAttemptPercentage)}%)` },
        { header: '2nd Attempt', value: `${formatNumber(totalSecondAttempt)}(${numberPrecision(totalSecondAttemptPercentage)}%)` },
        { header: '3rd Attempt', value: `${formatNumber(totalThirdAttempt)}(${numberPrecision(totalThirdAttemptPercentage)}%)` },
        { header: '3+ Attempt', value: `${formatNumber(totalMoreThanThreeAttempt)}(${numberPrecision(totalMoreThanThreeAttemptPercentage)}%)` },
    ]
    return (
        <Card elevation={1}>
            <CardHeader title='First Attempt Delivery Success Rate' action={(
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <ToggleButtonGroup color="primary" size="small" exclusive onChange={(_e, v) => setView(v)} value={view}>
                        <ToggleButton value="chart" title="View Chart">
                            <BarChartIcon fontSize="small" />
                        </ToggleButton>
                        <ToggleButton value="table" title="View Table">
                            <TableChartIcon fontSize="small" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Box>
            )} />
            <CardContent>
                <Grid container spacing={2}>
                    {summary.map((sum) => (
                        <Grid item xs={6} md={3} key={sum.header}>
                            <InfoBox {...sum} />
                        </Grid>
                    ))}
                    {(view === 'chart') && (
                        <Grid item xs={12}>
                            <StackedBarChart data={data} chartOptions={chartOptions} />
                        </Grid>
                    )}
                    {(view === 'table') && (
                        <Grid item xs={12}>
                            <MaterialTable exportExcel={false} size="small" rows={data} columns={tableColumns} frozenColumnsCount={1} />
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    )
}