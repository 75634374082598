import React, { useMemo, useState } from "react";
import { Card, CardContent, CardHeader, Grid, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useGetChannelOrderCountFUOutSideQuery, useGetChannelOrderCountFUQuery, useGetChannelOrderCountUFQuery } from "../../../redux/commonRTK";
import { subMonths } from "date-fns";
import { formatDate, formatDateForApi } from "../../../helpers/UtilityHelper";
import Loader from "../../../common/Loader";
import PageTitle from "../../../common/PageTitle";
import DateRangePicker from "../../DateRangePicker";

const ChannelOrderAnalytics = ({ merchantId }) => {

    const [dateRange, setDateRange] = useState([
        {
            startDate: subMonths(new Date(), 1),
            endDate: new Date(),
            key: 'selection'
        }
    ]);
    
    // get channel order counts

    const payloadUF = useMemo(() =>
        [
            {
                field: "createdDate",
                operator: "gte",
                value: formatDateForApi(dateRange[0].startDate),
            },
            {
                field: "createdDate",
                operator: "lte",
                value: formatDateForApi(dateRange[0].endDate),
            },
            {
                field: "channelOrderStatus",
                operator: "in",
                "value": ['CREATED', 'CANCELLED', 'HOLD', 'DELETED', 'RELEASE', 'PARTIALLY_FULLFILLED']
            },
            { "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + merchantId + '\')' }
        ], [dateRange, merchantId]);

    const payloadFU = useMemo(() =>
        [
            {
                field: "createdDate",
                operator: "gte",
                value: formatDateForApi(dateRange[0].startDate),
            },
            {
                field: "createdDate",
                operator: "lte",
                value: formatDateForApi(dateRange[0].endDate),

            },
            {
                field: "channelOrderStatus",
                operator: "eq",
                value: "FULFILLED",
            },
            { "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + merchantId + '\')' }
        ], [dateRange, merchantId]);

    const payloadFUOutSide = useMemo(() =>
        [
            {
                field: "createdDate",
                operator: "gte",
                value: formatDateForApi(dateRange[0].startDate),
            },
            {
                field: "createdDate",
                operator: "lte",
                value: formatDateForApi(dateRange[0].endDate),

            },
            {
                field: "channelOrderStatus",
                operator: "eq",
                value: "FULLFILLED_OUTSIDE",
            },
            { "field": "merchant.$id", "operator": "eq", "value": 'ObjectId(\'' + merchantId + '\')' }
        ], [dateRange, merchantId]);


    const { data: fulfilledData, isLoading: isLoadingFulfilled } = useGetChannelOrderCountFUQuery(payloadFU);
    const { data: unFulfilleddata, isLoading: isLoadingOther } = useGetChannelOrderCountUFQuery(payloadUF);
    const { data: fulfilledOutSideData, isLoading: isLoadingFUOutSide } = useGetChannelOrderCountFUOutSideQuery(payloadFUOutSide);

    const total = fulfilledData + unFulfilleddata
    const walletPercentage = total > 0 ? ((fulfilledData / total) * 100).toFixed(2) : 0


    const rows = [
        {
            id: 2,
            label: "Channel Orders Fulfilled on Prozo",
            value: fulfilledData,
        },
        {
            id: 6,
            label: "Channel Orders UnFulfilled",
            value: unFulfilleddata,
        },
        {
            id: 3,
            label: "Channel Orders Fulfilled Outside",
            value: fulfilledOutSideData,
        },
        {
            id: 1,
            label: "Total Channel Orders",
            value: (fulfilledData ?? 0) + (unFulfilleddata ?? 0) + (fulfilledOutSideData ?? 0)
        },
        {
            id: 4,
            label: "Share of Wallet Channel Orders (%)",
            value: `${walletPercentage}%`,
        },
    ];

    const columns = [
        {
            field: "label",
            headerName: "Field",
            flex: 1.2,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
        },
        {
            field: "value",
            headerName: "Value",
            flex: 1,
            renderCell: (params) => <Typography variant="body2">{params.value}</Typography>,
        },
        // {
        //     field: "action",
        //     headerName: "Action",
        //     flex: 0.5,
        //     sortable: false,
        //     renderCell: (params) => params.value,
        // },
    ];


    return (
        <>
            <PageTitle>Channel Order Analytics</PageTitle>
            {isLoadingFulfilled && isLoadingOther && isLoadingFUOutSide && <Loader />}
            <Card>
                <CardHeader
                    title={<Typography variant="subtitle2" color="error">
                        {`Channel Orders Overview (Ranges from : ${formatDate(dateRange[0].startDate, 'dd MMM, yyyy')} to ${formatDate(dateRange[0].endDate, 'dd MMM, yyyy')})`}
                    </Typography>
                    }
                    action={
                        <>
                            <Grid container spacing={2} sx={{ mt: 1 }}>
                            </Grid>
                            <Grid item xs={2} >
                                <DateRangePicker
                                    defaultRange={dateRange}
                                    maxDate={new Date()}
                                    onChange={(range) => setDateRange(range)}
                                />
                            </Grid>
                        </>
                    }
                />
                <CardContent>
                    <DataGrid
                        rows={rows}
                        columns={columns}
                        disableSelectionOnClick
                        hideFooterPagination
                        autoHeight
                    />
                </CardContent>
            </Card>
        </>
    );
};

export default ChannelOrderAnalytics;
