import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import * as React from "react";
import { FormProvider, RHFTextField } from "../../common/hook-form";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import LoginIcon from "@mui/icons-material/Login";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";
import { apiConstants } from "../../common/constants";
import { useSnackbar } from "notistack";
import { decodeAuthToken } from "../../helpers/UtilityHelper";
import { useDispatch } from "react-redux";
import { login, loginUserDataSlice } from "../../redux/userSlice";
import { fetchReportData } from "../../redux/actions/reportAction";
import { Link } from 'react-router-dom'

export default function LoginForm() {
  const [isLoading, setIsLoading] = React.useState(false);
  const [showPassword, setShowPassword] = React.useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const environment = process.env.REACT_APP_ENV;


  const methods = useForm({
    resolver: yupResolver(
      Yup.object().shape({
        username: Yup.string().required("Please enter username"),
        password: Yup.string().required("Please enter password"),
      })
    ),
    defaultValues: {
      username: "",
      password: "",
    },
  });
  const { handleSubmit, getValues, watch } = methods;
  const navigate = useNavigate();

  const onSubmit = async (reqData) => {
    reqData.panel = "MERCHANT";
    setIsLoading(true);

    try {
      let config = { headers: { "Content-Type": "application/json" } };
      const loginUrl = `${apiConstants.BASE_LOCAL}${apiConstants.AUTH}`;
      const authResponse = await axios.post(loginUrl, reqData, config);
      const { data: dataLogin } = authResponse;
      // store user's token in local storage
      localStorage.setItem("userToken", dataLogin.accessToken);
      dispatch(loginUserDataSlice({ ...dataLogin }))
      const { merchantOid, merchantList, roles } = decodeAuthToken(dataLogin.accessToken);

      // if users are assigned navigate to select user page
      if (roles?.includes('ROLE_PROSHIPADMIN') && (merchantList === undefined || merchantList?.length === 0 || merchantList?.length > 0)) {
        let url = "/loginuser";
        navigate(url);
      }
      else {
        let merchantData;
        config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("userToken"),
          },
        };
        let merchantUrl =
          apiConstants.BASE_LOCAL + apiConstants.GET_MERCHANT_SEARCH;
        const { data } = await axios.post(
          merchantUrl,
          {
            offset: 0,
            limit: 1,
            orfilter: [],
            andfilter: [{ field: "id", operator: "eq", value: merchantOid }],
            excludes: "mappedOrderRule,sla",
          },
          config
        );
        merchantData = data.data[0];
        // delete merchantData.codRemittanceConfig;
        delete merchantData.mappedOrderRule;
        delete merchantData.wallet;
        delete merchantData.pickup_details;
        delete merchantData.bank_details;
        delete merchantData.alertSubscription;
        delete merchantData.gstDetails;
        delete merchantData.merchantWebHooks;
        delete merchantData.activeSlas;
        delete merchantData.alertSubscriptionB2B;
        const userInfo = { ...dataLogin, merchant: merchantData };
        dispatch(login(userInfo));
        dispatch(fetchReportData(merchantOid));
      }
    } catch (e) {
      console.error(e);
      if (e.response?.status === 401) {
        enqueueSnackbar("Invalid username or password", { variant: "error" });
      } else if (e.response.status === 403) {
        enqueueSnackbar(e.response?.data || "Access Denied", { variant: "error" });
      } else {
        enqueueSnackbar(e.response.data?.error || "Login failed", {
          variant: "error",
        });
      }
    }
    setIsLoading(false);
  };

  const forgotPasswordHandler = () => {
    let url = "/forgot-password";
    const { username } = getValues();
    if (username !== "") {
      url += `?username=${username}`;
    }

    navigate(url);
  };

  const userNameValue = watch("username")

  const handleGoogleSignIn = () => {
    const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
    const redirectUri = process.env.REACT_APP_GOOGLE_REDIRECT_URI;
    const scope = process.env.REACT_APP_GOOGLE_SCOPE;
    const responseType = 'code';
    const googleUrl = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=${responseType}&scope=${scope}&access_type=offline`;
    window.location.href = googleUrl;
  };




  return (
    <div className="card card-login shadow-none border-0 p-0 bg-transparent">
      <div className="card-body" style={{ marginTop: '-30px', marginLeft: '20px' }}>
        <h1>Login to Proship</h1>
        <div className="d-flex align-items-center justify-center mt-3 p-2">
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <RHFTextField
                  name="username"
                  label="Email Address"
                  size="large"
                />
              </Grid>
              {environment !== "prod" ? (
                <>
                  {/* Show both Google Sign-In and Password Login for non-prod environments */}
                  <Grid item xs={12}>
                    <RHFTextField
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      size="large"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword((v) => !v)}
                          >
                            <VisibilityOffIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    <Box sx={{ width: "100%", display: "flex", my: 2 }}>
                      <LoadingButton
                        loading={isLoading}
                        type="submit"
                        startIcon={<LoginIcon />}
                        size="large"
                        color="warning"
                        variant="contained"
                      >
                        Login
                      </LoadingButton>
                      <Button
                        sx={{
                          ml: 2,
                          textTransform: "capitalize",
                          fontWeight: 500,
                        }}
                        onClick={forgotPasswordHandler}
                        variant="text"
                      >
                        Forgot password
                      </Button>
                    </Box>
                  </Grid>
                  <Grid item xs={12} sx={{ mt: -2 }}>
                    <Button
                      onClick={handleGoogleSignIn}
                      variant="contained"
                      color="primary"
                    >
                      Sign in with Prozo Gmail Account
                    </Button>
                  </Grid>
                </>
              ) : userNameValue.endsWith("@prozo.com") ? (
                <Grid item xs={12}>
                  {/* Show Google Sign-In button for Prozo email */}
                  <Button
                    onClick={handleGoogleSignIn}
                    variant="contained"
                    color="primary"
                  >
                    Sign in with Prozo Gmail Account
                  </Button>
                </Grid>
              ) : (
                <>
                  <Grid item xs={12}>
                    {/* Show password and login for non-Prozo emails */}
                    <RHFTextField
                      name="password"
                      label="Password"
                      type={showPassword ? "text" : "password"}
                      size="large"
                      InputProps={{
                        endAdornment: (
                          <IconButton
                            onClick={() => setShowPassword((v) => !v)}
                          >
                            <VisibilityOffIcon />
                          </IconButton>
                        ),
                      }}
                    />
                  </Grid>
                  <Box sx={{ width: "100%", display: "flex", my: 3 }}>
                    <LoadingButton
                      loading={isLoading}
                      type="submit"
                      startIcon={<LoginIcon />}
                      size="large"
                      color="warning"
                      variant="contained"
                      sx={{ ml: 4 }}
                    >
                      Login
                    </LoadingButton>
                    <Button
                      sx={{
                        ml: 2,
                        textTransform: "capitalize",
                        fontWeight: 500,
                      }}
                      onClick={forgotPasswordHandler}
                      variant="text"
                    >
                      Forgot password
                    </Button>
                  </Box>
                </>
              )}
            </Grid>
          </FormProvider>
          <Box sx={{
            position: 'absolute',
            bottom: 0,
            left: '30px',
            width: '120%'
            //  bottom: -10, width: '120%' 
          }}>
            <Grid container spacing={0.1}>
              <Grid item xs={12} sx={{ display: 'flex', flexWrap: 'nowrap' }}>
                <Button
                  variant="text"
                  color="error"
                  component={Link}
                  to={'/toc'}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ fontSize: '12px', textTransform: 'none' }}
                >
                  Terms & Conditions
                </Button>
                <Button
                  variant="text"
                  color="error"
                  component={Link}
                  to={'/privacy-policy'}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ fontSize: '12px', textTransform: 'none' }}
                >
                  Privacy Policy
                </Button>
                <Button
                  component={Link}
                  to={'/refund_cancellation-policy'}
                  variant="text"
                  color="error"
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{ fontSize: '12px', textTransform: 'none' }}
                >
                  Refund & Cancellation Policy
                </Button>
              </Grid>

              {/* Learn More Button */}
              <Grid item xs={12}>
                <Button
                  onClick={() => { window.open('https://www.prozo.com/', '_blank') }}
                  variant="text"
                  color="primary"
                  sx={{ fontSize: '12px' }}
                >
                  Learn More About Prozo!
                </Button>
              </Grid>

              {/* Contact Us */}
              <Grid item xs={12} sx={{ ml: 1 }}>
                <Typography variant="button" color="error" sx={{ fontSize: '12px' }}>
                  Contact Us: <a href="tel:+91 9311847248">+91 9311847248</a>
                </Typography>
              </Grid>
            </Grid>
          </Box>

        </div>
      </div>
    </div>
  );
  //   <div className='card card-login shadow-none border-0 p-0 bg-transparent'>
  //     <div className='card-body'>
  //       <h1>Login to Proship</h1>
  //       <div className='d-flex align-items-center justify-center mt-5 p-2'>
  //         <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
  //           <Grid container spacing={4}>
  //             <Grid item xs={12}>
  //               <RHFTextField
  //                 name='username'
  //                 label='Email Address'
  //                 size='large'
  //               />
  //             </Grid>
  //             <Grid item xs={12}>
  //               <RHFTextField
  //                 InputProps={{
  //                   endAdornment: (
  //                     <IconButton onClick={() => setShowPassword((v) => !v)}>
  //                       <VisibilityOffIcon />
  //                     </IconButton>
  //                   ),
  //                 }}
  //                 name='password'
  //                 size='large'
  //                 label='Password'
  //                 type={showPassword ? "text" : "password"}
  //               />
  //               <Box sx={{ width: "100%", display: "flex", my: 3 }}>
  //                 <LoadingButton
  //                   loading={isLoading}
  //                   type='submit'
  //                   startIcon={<LoginIcon />}
  //                   size='large'
  //                   color='warning'
  //                   variant='contained'>
  //                   Login
  //                 </LoadingButton>
  //                 <Button
  //                   sx={{ ml: 2, textTransform: "capitalize", fontWeight: 500 }}
  //                   onClick={forgotPasswordHandler}
  //                   variant='text'>
  //                   Forgot password
  //                 </Button>
  //               </Box>
  //             </Grid>
  //           </Grid>
  //         </FormProvider>
  //       </div>
  //     </div>
  //   </div>
  // );
}
