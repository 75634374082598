import React from 'react';
import { useFormContext, Controller } from 'react-hook-form';
import DateRangePicker from '../../components/DateRangePicker';

export default function RHFDateRangePickerField(props) {
  const { control, setValue, watch } = useFormContext();
  const fromDate = watch('fromDate');
  const toDate = watch('toDate');


  return (
    <React.Fragment>
      <DateRangePicker
        showTextBox={true}
        label={props.label}
        maxDate={props.maxDate}
        // defaultRange={props.defaultRange}
        defaultRange={fromDate && toDate ? [{ startDate: fromDate, endDate: toDate, key: 'selection' }] : []}
        onChange={(range) => {
          if (range.length > 0) {
            setValue('fromDate', range[0].startDate);
            setValue('toDate', range[0].endDate);
          }
        }}
        InputLabelProps={{ style: { fontSize: 14 } }}
        {...props}
      />
      <Controller
        name={props.name ? props.name : "fromDate"}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <input
            {...field}
            type="hidden"
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          />
        )}
      />
      <Controller
        name={props.name ? props.name : "toDate"}
        control={control}
        render={({ field, fieldState: { error } }) => (
          <input
            {...field}
            type="hidden"
            value={typeof field.value === 'number' && field.value === 0 ? '' : field.value}
          />
        )}
      />
    </React.Fragment>
  )
}
